import React from "react";
import {graphql} from "gatsby";
import Layout from "../components/Layout";

import Head from "../components/Head";
import Landing from "../components/landing2024/Landing";
import Schritte from "../components/landing2024/Schritte";
import Team from "../components/landing2024/Team";
import Warum from "../components/landing2024/Warum";
import Newsletter from "../components/landing2024/Newsletter";
import Empfehlung from "../components/landing2024/Empfehlung";
import Referenzen from "../components/landing2024/Referenzen";
import Kontakt from "../components/landing2024/Kontakt";
import Info from "../components/landing2024/Info";


export const query = graphql`
    {
        allSanityHome2024{
            edges{
                node{
                    pageBuilder {
                        ... on SanityHero {
                            _type
                            image {
                                alt
                                asset {
                                    gatsbyImageData
                                    _type
                                }
                            }
                            button {
                                title
                                link
                            }
                            _rawHeadline
                            subheadline
                            tags
                        }
                        ... on SanityInfo {
                            objectFit
                            _type
                            _rawHeadline
                            _rawText
                            button{
                                title
                                link
                            }
                            image {
                                alt
                                asset {
                                    gatsbyImageData
                                }
                            }
                            services {
                                title
                                link
                            }
                            _rawSubheadline
                            color
                        }
                        ... on SanityKontakt {
                            _type
                            headline
                            telefon
                        }
                        ... on SanityNewsletter {
                            _type
                            _rawText
                            headline
                            subheadline
                        }
                        ... on SanityReferenzen {
                            _type
                            _rawHeadline
                            _rawHeadline2
                            subheadline
                            images {
                                alt
                                asset {
                                    gatsbyImageData
                                }
                            }
                        }
                        ... on SanitySchritte {
                            _type
                            _rawHeadline
                            color
                            services {
                                title
                                link
                            }
                            button{
                                title
                                link
                            }
                            steps {
                                title
                                _rawText
                                image {
                                    alt
                                    asset {
                                        gatsbyImageData
                                    }
                                }
                            }
                        }
                        ... on SanityWarum {
                            _type
                            color
                            button {
                                link
                                title
                            }
                            _rawHeadline
                            steps {
                                title
                                _rawText
                                image {
                                    alt
                                    asset {
                                        gatsbyImageData
                                    }
                                }
                            }
                        }
                    }
                    title
                    seo{
                        seo_title
                    }
                }
            }
        }
  allSanitySettings {
    edges {
      node {
        instalink
        fblink
        linkedinlink
      }
    }
  }
}
`


const IndexPage = ({data}) => {

    const page24 = data.allSanityHome2024.edges[0].node;

    return (
        <Layout landing={true} settings={data.allSanitySettings.edges[0].node} landing2024={true}>
            <Head/>
            {page24?.pageBuilder?.map((section, index) => {
                if(section._type === 'hero'){
                    return <div key={index} id={index === 1 ? 'start' : ''}><Landing key={index} data={section}/></div>
                } else if(section._type === 'schritte'){
                    return <div key={index} id={index === 1 ? 'start' : ''}><Schritte  data={section}/></div>
                }
                else if(section._type === 'info'){
                            return <div key={index} id={index === 1 ? 'start' : ''}><Info  data={section}/></div>
                }
                else if(section._type === 'warum'){
                            return <div key={index} id={index === 1 ? 'start' : ''}><Warum  data={section}></Warum></div>
                }
                else if(section._type === 'referenzen'){
                            return <div key={index} id={index === 1 ? 'start' : ''}><Referenzen  data={section}/></div>
                }
                else if(section._type === 'newsletter'){
                            return <div key={index} id={index === 1 ? 'start' : ''}><Newsletter  data={section}/></div>
                }
                else if(section._type === 'kontakt'){
                            return <div key={index} id={index === 1 ? 'start' : ''}><Kontakt  data={section}/></div>
                }
            })}
        </Layout>
    )
}

export default IndexPage;


/*

 */
