import React from "react"
import {Link} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";

const Empfehlung = ({image}) => {
    return <div className={"flex flex-col-reverse laptop:flex-row phone:h-screen bg-bluegreen"}>
        <div className="laptop:w-1/2 p-base flex flex-col justify-between">
            <div>
                <h2 className="uppercase text-3xl phonexl:text-4xl !leading-[.9] tablet:text-5xl wide:text-6xl">
                    <span className="text-orange">400 Euro</span> <br/>Für Ihre Empfehlungen!
                </h2>
            </div>
            <div className={"mt-doublebase"}>
                <div className="flex phone:items-end flex-col phone:flex-row">
                    <div className="mr-base desktop:mr-doublebase">
                        <div className="list__container !flex !w-fit mb-base phone:mb-0">
                            <Link className="list__element smalltext hover-moveup" to={"/service/photovoltaik"}><span className="listnr">01</span>Photovoltaik</Link>
                            <Link className="list__element smalltext hover-moveup" to={"/service/batteriespeicher"}><span className="listnr">02</span>Batteriespeicher</Link>
                            <Link className="list__element smalltext hover-moveup" to={"/service/batteriespeicher"}><span className="listnr">03</span>Wärme</Link>
                            <Link className="list__element smalltext hover-moveup" to={"/service/batteriespeicher"}><span className="listnr">04</span>E-Mobilität</Link>
                        </div>
                    </div>
                    <div className="word-break">
                        <p className="small max-w-md">
                            Mit unseren Referal-Programm unseres Partners SOLAR EDGE sparen künftig Empfehlungsgeber:innen und Empfehlungsnehmer:innen. Das Referal-Programm gilt für alle Produktspezifikationen.
                            <br/><br/>
                            <span className={"bold"}>
                            Empfehlungsgeber:innen erhalten 300 Euro. <br/>
                            Empfehlungsnehmer:innen erhalten 100 Euro.</span>
                        </p>
                    </div>
                </div>
                <div className={"mt-base"}>
                    <a href={'#kontakt'} className="btn border border-black hover:bg-black hover:text-white landingbtn smalltext bold uppercase phonexl:!px-12 !w-full phonexl:!w-fit">
                        Holen sie ihr angebot ein!
                    </a>
                </div>
            </div>
        </div>
        <div className="laptop:w-1/2 bg-orange h-[50vh] phone:h-full overflow-hidden">
            <div className="w-full flex justify-center items-center  overflow-hidden h-full">
                <GatsbyImage className={"h-full w-full"} alt={"text"} image={image.asset.gatsbyImageData}></GatsbyImage>
            </div>
        </div>
    </div>
}

export default Empfehlung

