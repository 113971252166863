import React from "react"
import {Link} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";

const Team = ({image}) => {
    return <div className={"flex flex-col laptop:flex-row laptop:h-screen"}>
        <div className="laptop:w-1/2 p-base flex flex-col justify-between">
            <div>
                <h2 className="uppercase text-3xl phonexl:text-4xl !leading-[.9] tablet:text-5xl wide:text-6xl break-words">
                    ENIOSOL - <br/><span className="text-orange">komplettanbieter</span> <br/>aus dem <br/><span className="text-orange">allgäu</span><br/>und dem <br/><span className="text-orange">fünf-seen-land</span>
                </h2>
                <p className={"uppercase bold leading-[1] mt-base"}>
                    von der idee <br/>bis zur fertigen PV-anlage
                </p>
            </div>

            <div className={"mt-doublebase"}>
                <div className="flex phone:items-end flex-col phone:flex-row">
                    <div className="mr-base desktop:mr-doublebase">
                        <div className="list__container !flex !w-fit mb-base phone:mb-0">
                            <Link className="list__element smalltext hover-moveup" to={"/service/photovoltaik"}><span className="listnr">01</span>Photovoltaik</Link>
                            <Link className="list__element smalltext hover-moveup" to={"/service/batteriespeicher"}><span className="listnr">02</span>Batteriespeicher</Link>
                            <Link className="list__element smalltext hover-moveup" to={"/service/batteriespeicher"}><span className="listnr">03</span>Wärme</Link>
                            <Link className="list__element smalltext hover-moveup" to={"/service/batteriespeicher"}><span className="listnr">04</span>E-Mobilität</Link>
                        </div>
                    </div>
                    <div className="">
                        <p className="small max-w-md">
                            Als lokale Anbieter aus Bayern bedienen wir Sie direkt und persönlich von zwei Standorten aus: <span className={"bold"}>Grünenbach im Allgäu</span> und <span className={"bold"}>Altenstadt im Fünf–Seen–Land</span>
                        </p>
                    </div>
                </div>
                <div className={"mt-base"}>
                    <a href={'#kontakt'} className="btn border border-black hover:bg-black hover:text-white landingbtn smalltext bold uppercase phonexl:!px-12 !w-full phonexl:!w-fit">
                        Rufen sie uns gerne an!
                    </a>
                </div>
            </div>

        </div>
        <div className="laptop:w-1/2 bg-orange max-h-[50vh] h-full laptop:max-h-[unset] flex flex-col justify-evenly">
            {/*{[...Array(3)].map((_, rowIndex) => (
                <div key={rowIndex} className="flex w-full h-full max-h-[33.33%]">
                    {[...Array(3)].map((_, colIndex) => (
                        <div className="aspect-square rounded-full overflow-hidden">
                            <GatsbyImage className={""} alt={"text"} image={image.asset.gatsbyImageData}></GatsbyImage>
                        </div>
                    ))}
                </div>
            ))}*/}
            <div className="w-full flex justify-center items-center overflow-hidden h-full">
                <GatsbyImage className={"h-full w-full"} alt={"text"} image={image.asset.gatsbyImageData}></GatsbyImage>
            </div>
        </div>
    </div>
}

export default Team

